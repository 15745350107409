import React from "react";
import Header from "./components/Header";
import Form from "./components/Form";
import Footer from "./components/Footer";

import './assets/styles.scss'

export default function UpdateInfos() {
  return (
    <div className="update-infos">
      <Header />
      <Form />
      <Footer />
    </div>
  );
}