import React from "react";
import { useFormContext } from "react-hook-form";
import cx from "classnames";

import './styles.scss';

interface InputProps {
  defaultValue?: string,
  name: string,
  required?: boolean | string,
  label: string
  errorText?: string
  disabled?: boolean,
}

export default function Input({
  defaultValue,
  name,
  required,
  label,
  errorText,
  disabled
}: InputProps) {

  const { register } = useFormContext()

  return (
    <div className={cx(`input-container ${name}`, {
      errored: !!errorText
    })} >
      <label htmlFor={name}>
        <input
          id={name}
          type="text"
          className="input"
          placeholder=" "
          defaultValue={defaultValue}
          disabled={disabled}
          {...register(name, { required, })}
        />
        <span>{label}</span>
      </label>
      {errorText ? <span className="error">{errorText}</span> : null}
    </div>
  );
}