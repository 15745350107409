import { useEffect, useState } from "react";
import { IUser } from "utils/types";
import useAzureToken from "./useAzureToken";

export default function useSessionUser() {
  const [user, setUser] = useState<IUser>();
  const token = useAzureToken();

  useEffect(() => {
    async function getUser() {
      const user = await fetch(
        `${process.env.REACT_APP_API_URL}/api/users/me`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      const userJson: IUser = await user.json();
      setUser(userJson);
    }

    if (token) {
      getUser();
    }
  }, [token]);

  return { user, token };
}