import { AppProvider } from "contexts/AppContext";
import { createRoot } from 'react-dom/client';
import React from "react";
import App from "./App";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./utils/auth";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import "./styles/index.scss";

const msalInstance = new PublicClientApplication(msalConfig);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "*",
    element: <p>Page non trouvée</p>,
  },
]);

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <AppProvider>
    <MsalProvider instance={msalInstance}>
      <RouterProvider router={router} />
    </MsalProvider>
  </AppProvider>
);
