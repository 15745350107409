export const genderList = [
  { id: 0, code: "01", label: "Madame" },
  { id: 1, code: "02", label: "Monsieur" },
  { id: 2, code: "--", label: "Autre" },
];
export const activityList = [
  { id: 0, code: "PPRI", label: "Plaisance privée" },
  { id: 1, code: "PRO", label: "Plaisance professionnelle" },
  { id: 2, code: "FRET", label: "Fret" },
  { id: 3, code: "PAMI", label: "N/A" },
];
