import React, { useContext } from "react";
import cx from "classnames";
import { useSelect } from "downshift";
import ArrowDownIcon from "./arrow-down-small.svg";
import ArrowUpIcon from "./arrow-up-small.svg";

import { OptionType } from "utils/types";
import { useFormContext } from "react-hook-form";

import "./styles.scss";
import { AppContext } from "contexts/AppContext";

export interface ISelectProps {
  options: OptionType[];
  label?: string;
  value?: OptionType;
  className?: string;
  errored?: boolean;
  name: string;
  errorText?: string;
  defaultValue?: string
  required?: string | boolean
}

function Select(
  {
    options,
    name,
    label,
    defaultValue,
    className,
    errorText,
    required,
  }: ISelectProps,
) {

  const { setValue, register } = useFormContext()
  const { isMobile } = useContext(AppContext);

  const {
    isOpen,
    getMenuProps,
    getItemProps,
    selectedItem,
    getToggleButtonProps,
    closeMenu
  } = useSelect({
    items: options,
    initialSelectedItem: options.find(o => o.code === defaultValue) || null,
    onSelectedItemChange: (changes) => {
      setValue(name, changes.selectedItem, { shouldDirty: true })
      closeMenu();
    },
  });
  const hasError = !!errorText

  return (
    <div
      {...getToggleButtonProps()}
      className={cx("input-container", className, {
        mobile: isMobile,
        errored: hasError
      })}
    >
      <input type="hidden" {...register(name, { required })} />
      <label htmlFor={name}>
        <div className={cx("select-btn", { mobile: isMobile })} >
          {selectedItem?.label || label}
          <img
            alt="arrow"
            src={isOpen ? ArrowUpIcon : ArrowDownIcon}
            className="toggle-icon"
          />
        </div>
      </label>
      {errorText ? <span className="error">{errorText}</span> : null}
      <ul
        {...getMenuProps()}
        className="search-box"
      >
        {isOpen &&
          options.map((item, index) => (
            <li
              key={`${item.id}`}
              {...getItemProps({ item, index })}
            >
              <span>{item.label}</span>
            </li>
          ))}
      </ul>
    </div>
  );
}

export default Select;
