import React, { useState } from 'react';
import cx from 'classnames';
import { Carousel } from 'react-responsive-carousel';
import Ademe from "./logo-carousel/logo-ademe.jpg";
import Cnr from "./logo-carousel/logo-CNR.svg";
import Haropa from "./logo-carousel/logo-HAROPA.jpg";
import Ministere from "./logo-carousel/logo-ministere.png";
import Quadri from "./logo-carousel/logo-quadri.jpg";
import RegionNormandie from "./logo-carousel/logo-region-normandie.jpg";
import Ridf from "./logo-carousel/logo-RIDF.jpg";
import Pause from "./indictors-carousel/pause.png"
import Play from "./indictors-carousel/play.png"

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

export default function CarouselComponent() {

  const [isPlaying, setIsPlaying] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(1);

  return (
    <div className="carousel-wrapper">
      <Carousel
        showArrows={false}
        showStatus={false}
        infiniteLoop
        showIndicators={false}
        autoPlay={isPlaying}
        showThumbs={false}
        onChange={(index) => setCurrentIndex(index)}
        selectedItem={currentIndex}
      >
        <div className="bloc-carousel">
          <img className="carousel-img" alt="Ademe" src={Ademe} />
          <img className="carousel-img" alt="Cnr" src={Cnr} />
          <img className="carousel-img" style={{ maxWidth: 230 }} alt="Haropa" src={Haropa} />
        </div>
        <div className="bloc-carousel">
          <img className="carousel-img" alt="Ministere" src={Ministere} />
          <img className="carousel-img" alt="Quadri" src={Quadri} />
          <img className="carousel-img" alt="RegionNormandie" src={RegionNormandie} />
        </div>
        <div className="bloc-carousel">
          <div className='carousel-empty-block' />
          <img className="carousel-img" alt="Ridf" src={Ridf} />
          <div className='carousel-empty-block' />
        </div>
      </Carousel>
      <div className="indicators">
        <img onClick={() => setIsPlaying(!isPlaying)} className="play-pause" src={isPlaying ? Pause : Play} alt="status" />
        <div className="indexes">
          <div className={cx("indicator", {
            on: currentIndex === 0,
            off: currentIndex !== 0
          })}
            onClick={() => setCurrentIndex(0)}
          />
          <div className={cx("indicator", {
            on: currentIndex === 1,
            off: currentIndex !== 1
          })}
            onClick={() => setCurrentIndex(1)}
          />
          <div className={cx("indicator", {
            on: currentIndex === 2,
            off: currentIndex !== 2
          })}
            onClick={() => setCurrentIndex(2)}
          />
        </div>
      </div>
    </div>
  )
}
