import React from "react";
import { useMediaQuery } from "react-responsive";

type ContextProps = {
  isDesktopOrLaptop: boolean;
  isMobile: boolean;
};
const AppContext = React.createContext<ContextProps>({
  isDesktopOrLaptop: false,
  isMobile: false,
});

const AppProvider = (props: { children: React.ReactNode }) => {
  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 1224 });
  const isMobile = useMediaQuery({ maxDeviceWidth: 767, maxWidth: 767 });

  return (
    <AppContext.Provider
      value={{
        isDesktopOrLaptop,
        isMobile,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
