import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useState, useEffect } from "react";
import { loginRequest } from "../auth";

export default function useAzureToken() {
  const { instance, inProgress, accounts } = useMsal();
  const [token, setToken] = useState<string>();
  useEffect(() => {
    const accessTokenRequest = {
      scopes: loginRequest.scopes,
      account: accounts[0],
    };
    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        // Acquire token silent success
        let accessToken = accessTokenResponse.accessToken;
        // Call your API with token
        setToken(accessToken);
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect(accessTokenRequest);
        }
        console.log(error);
      });
  }, [instance, accounts, inProgress]);

  return token
}