import React from "react";
import { useFormContext } from "react-hook-form";
import cx from "classnames";

import '../Input/styles.scss';

interface InputProps {
  defaultValue?: string,
  errorText?: string
}

export default function InputPhone({
  defaultValue,
  errorText,
}: InputProps) {

  const { register } = useFormContext()

  return (
    <div className={cx(`input-container telephone`, {
      errored: !!errorText
    })} >
      <label htmlFor="telephone">
        <input
          id="telephone"
          type="text"
          className="input"
          placeholder="+33600000000"
          defaultValue={defaultValue}
          {...register("telephone", {
            pattern: {
              message: "Le format est incorrect",
              value: /^(0|\+33)[0-9]{9}$/
            },
          })}
        />
        <span>Numéro de téléphone</span>
      </label>
      {errorText ? <span className="error">{errorText}</span> : null}
    </div>
  );
}