import React from "react";
import cx from "classnames"
import { AppContext } from "contexts/AppContext";
import { useContext } from "react";
import LogoFooter from "../assets/img/logo-footer.svg";
import Carousel from "components/Carousel/Carousel";

import '../assets/footer.scss'

export default function Footer() {
  const { isMobile } = useContext(AppContext);

  return (
    <div
      className={cx("footer", {
        mobile: isMobile
      })}
    >
      <div className="links-logo">
        <span className="links">
          <a target="_blank" href={process.env.REACT_APP_CONTACT_URL} rel="noreferrer">Contact</a>
          <span> | </span>
          <a target="_blank" href={process.env.REACT_APP_COOKIE_URL} rel="noreferrer">Cookies</a>
          <span> | </span>
          <a target="_blank" href={process.env.REACT_APP_LEGAL_URL} rel="noreferrer">Mentions légales</a>
        </span>
        <a className="footer-logo" href={process.env.REACT_APP_VNF_WEBSITE_URL} target="_blank" rel="noreferrer">
          <img src={LogoFooter} alt="logo" />
        </a>
      </div>

      <div
        className={cx("footer-carousel", {
          mobile: isMobile,
        })}
      >
        <Carousel />
      </div>
    </div>
  )
}
