import React, { useContext, useEffect } from "react"
import useSessionUser from "utils/hooks/useSessionUser";
import IconUser from "../assets/img/round-user.svg";
import cx from "classnames";

import '../assets/form.scss';
import { IUser, OptionType } from "utils/types";
import { FormProvider, useForm } from "react-hook-form";
import Select from "components/Form/Select/Select";
import { activityList, genderList } from "../core/constants";
import Input from "components/Form/Input/Input";
import AddressSelect from "components/Form/AddressSelect/AddressSelect";
import InputPhone from "components/Form/InputPhone";
import request from "utils/request";
import { AppContext } from "contexts/AppContext";

interface IForm {
  gender?: OptionType;
  firstName: string;
  lastName: string;
  address: string;
  email: string;
  telephone: string;
  postalCode: string;
  street: string;
  housenumber: string;
  activity?: OptionType;
  token: string;
  city: string;
  rgpd: boolean;
  commercial: boolean
}

function User({ firstName, lastName }: { firstName: string, lastName: string }) {
  return (
    <div className="user">
      <img src={IconUser} alt="round-user" className="" />
      <span className="user-text">
        Bienvenue <br />
        {firstName} {lastName}
      </span>
    </div>
  )
}

function FormHeader() {
  return (
    <div className="form-header">
      <div className="title">
        Vérifier mes données
      </div>
      <div className="subtitle">Mettez à jour vos informations si nécessaire</div>
    </div>
  )
}

function getUserAddress(user: IUser) {
  let defaultAdress = '';
  if (user.mdm) {
    const { street, housenumber, postalCode, city } = user.mdm?.address;
    if (street === "INCONNU") {
      defaultAdress = `INCONNUE`;
    } else {
      defaultAdress = `${housenumber} ${street} ${postalCode} ${city}`;
    }
  }
  return defaultAdress;
}


function redirectToPami() {
  window.location.href = process.env.REACT_APP_PAMI_URL || ""
}

async function createOrUpdateMDM(data: IForm, token: string) {
  const result = {
    status: "",
    message: ""
  }
  try {
    const update = await request(`${process.env.REACT_APP_API_URL}/api/mdm/users`, {
      method: "PUT",
      body: data,
      headers: {
        "Authorization": `Bearer ${token}`
      }
    })
    result.status = update.codeRetour || "KO"
    result.message = update.libErreur || ""
  } catch (error) {
    console.error(error);
    result.status = "KO"
    result.message = "Une erreur est survenue"
  } finally {
    console.log('result', result);
    return result;
  }
}


export default function Form() {
  /** HOOKS */
  const { user, token } = useSessionUser();
  const methods = useForm<IForm>({ mode: "onBlur" });
  const { isMobile } = useContext(AppContext);

  /** VARIABLES */
  const { handleSubmit, register, formState: { errors, isDirty, isSubmitting }, setValue, setError } = methods;
  const hasUserMDM = !!user?.mdm;


  /** UseEffects */
  useEffect(() => {
    if (user) {
      setValue("firstName", user.mdm?.firstName || user.firstName);
      setValue("lastName", user.mdm?.lastName || user.lastName);
      setValue("email", user.email);
      if (user.mdm) {
        setValue("gender", genderList.find(a => a.code === user.mdm?.gender))
        setValue("activity", activityList.find(a => a.code === user.mdm?.activityCode))
        setValue("housenumber", user.mdm.address.housenumber)
        setValue("postalCode", user.mdm.address.postalCode)
        setValue("street", user.mdm.address.street)
        const { street, housenumber, postalCode, city } = user.mdm.address;
        if (street === "INCONNU") {
          setValue("address", `inconnue`)
        } else {
          setValue("address", `${housenumber} ${street} ${postalCode} ${city}`)
        }
        setValue("city", city)
      }
    }
  }, [setValue, user]);

  async function onSubmit(data: IForm) {
    if (!isDirty) {
      window.location.href = process.env.REACT_APP_PAMI_URL || ""
    } else {
      // Si l'utilisateur n'est pas dans le MDM
      // Et qu'il n'accepte pas que ses données sont collectées
      if (!hasUserMDM && !data.rgpd) {
        // Si la personne a modifié le formulaire
        // mais pas accepté la partie RGPD
        setError("rgpd", { message: "Vous n'avez pas accepté la sauvegarde des données. Vos données ne pourront pas être utilisées pour la suite du traitement." })
      } else {
        if (!token) {
          window.location.reload();
        } else {
          const result = await createOrUpdateMDM(data, token);
          if (result.status !== "OK") {
            let message = `Une erreur est survenue. Merci de réessayer ultérieurement`
            if (result.message) {
              message = `Une erreur est survenue avec le message : ${result.message}. Merci de réessayer ultérieurement`
            }
            alert(message);
          } else {
            if (process.env.NODE_ENV !== "development") {
              redirectToPami();
            }
          }
        }
      }
    }
  }

  if (!user) {
    return (
      <div className="form">
        Chargement en cours ...
      </div>
    )
  }

  return (
    <div className={cx("form", {
      mobile: isMobile
    })}>
      <User firstName={user.firstName} lastName={user.lastName} />
      <FormHeader />
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="main-form"
        >
          {errors?.rgpd?.message ? (
            <div className="rgpd-error">
              <span>{errors.rgpd.message}</span>
            </div>
          ) : null}
          <div className="line-separator" />
          <div className="inner-form">
            <Select
              className="gender"
              name="gender"
              label="Civilité"
              options={genderList}
              defaultValue={user.mdm?.gender}
              errorText={errors?.gender?.message}
              required={(hasUserMDM || isDirty) && "Civilité manquante"}
            />
            <Input
              defaultValue={user.mdm?.firstName || user.firstName}
              name="firstName"
              required={(hasUserMDM || isDirty) && "Merci de saisir un prénom"}
              label="Prénom*"
              errorText={errors?.firstName?.message}
            />
            <Input
              defaultValue={user.mdm?.lastName || user.lastName}
              name="lastName"
              required={(hasUserMDM || isDirty) && "Merci de saisir un nom"}
              label="Nom*"
              errorText={errors?.lastName?.message}
            />
            <AddressSelect
              label="Adresse*"
              defaultValue={getUserAddress(user)}
              errorText={errors?.address?.message}
              required={(hasUserMDM || isDirty) && "Adresse manquante"}
            />
            <Input
              defaultValue={user.email || ""}
              name="email"
              label="Email"
              disabled
            />
            <InputPhone
              defaultValue={user.mdm?.phoneNumber || ""}
              errorText={errors?.telephone?.message}
            />
            <Select
              className="activity"
              label="Activité"
              name="activity"
              defaultValue={user.mdm?.activityCode}
              options={activityList}
              errorText={errors?.activity?.message}
              required={(hasUserMDM || isDirty) && "Activité manquante"}
            />
          </div>
          <div className="rgpd">
            {!user.mdm ? (
              <div>
                <input type="checkbox" {...register("rgpd")} />
                <label className="text-rgpd">
                  J'ai bien pris connaissance des renseignements relatifs à la&nbsp;
                  <a target="_blank" href={process.env.REACT_APP_LEGAL_PDF} rel="noreferrer">
                    protection des données
                  </a>
                  &nbsp;ainsi que les&nbsp;
                  <a target="_blank" href={process.env.REACT_APP_CGU_PDF} rel="noreferrer">
                    conditions générales d’utilisation de ce site
                  </a>.
                </label>
              </div>
            ) : (
              <div>
                <label className="text-rgpd">
                  Consulter les renseignements relatifs à la&nbsp;
                  <a target="_blank" href={process.env.REACT_APP_LEGAL_PDF} rel="noreferrer">
                    protection des données
                  </a>
                  &nbsp;ainsi que les&nbsp;
                  <a target="_blank" href={process.env.REACT_APP_CGU_PDF} rel="noreferrer">
                    conditions générales d’utilisation de ce site
                  </a>.
                </label>
              </div>
            )}
            {/* <div>
              <input type="checkbox" {...register("commercial")} />
              <label className="text-rgpd">
                J’accepte que mes données soient utilisées à des fins de prospection commerciale concernant la fluvial
              </label>
            </div> */}
          </div>
          <div className="line-separator" />
          <div className="buttons">
            {isDirty || !user.mdm ? (
              <button
                className="cancel-btn"
                onClick={(event) => {
                  event.preventDefault()
                  event.stopPropagation()
                  window.location.reload()
                }}
              >
                Annuler
              </button>
            ) : <span />}
            <button
              type="submit"
              className="save-btn"
              disabled={!!errors.rgpd?.message || isSubmitting}
            >
              {(user.mdm && !isDirty) ? "Valider" : "Enregistrer"}
            </button>
          </div>
          <div className="required-fields">
            <span>* Champs obligatoires</span>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}