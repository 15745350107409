import React from "react"
import separator from "../../Home/assets/separator.svg";

import '../assets/header.scss'

export default function Header() {
  return (
    <div className="header">
      <div className="title">
        <h1>PAMI <span className="connect">Connect</span></h1>
        <img className="separator" src={separator} alt="pami logo separator" />
      </div>
    </div>
  );
}