import { useCombobox } from "downshift";
import { DebouncedFunc, debounce } from "lodash";
import React, { useState, useRef, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { GeoFeature } from "utils/types";
import cx from "classnames";
import { AppContext } from "contexts/AppContext";


type LodashDebounceFunc = DebouncedFunc<
  (downshiftInput: { inputValue?: string }) => void
>;

interface AddressSelectProps {
  label: string;
  errorText?: string
  defaultValue?: string;
  required: string | boolean
}

export default function AddressSelect({
  label,
  errorText,
  defaultValue,
  required
}: AddressSelectProps) {
  const { setValue, register } = useFormContext();
  const { isMobile } = useContext(AppContext);
  const [adresses, setAdresses] = useState<GeoFeature[]>([]);

  const debounceUserInput = useRef<LodashDebounceFunc | null>(null);

  if (!debounceUserInput.current) {
    debounceUserInput.current = debounce(makeAdressCall, 500);
  }

  function makeAdressCall(downshiftInput: { inputValue: string }) {
    if (downshiftInput.inputValue) {
      fetch(
        `https://api-adresse.data.gouv.fr/search/?q=${downshiftInput.inputValue}`,
      )
        .then((res) => res.json())
        .then((res: { features: GeoFeature[] }) => {
          setAdresses(res.features);
        });
    }
  }
  const hasError = !!errorText;

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getItemProps,
  } = useCombobox({
    onInputValueChange: debounceUserInput.current,
    items: adresses,
    itemToString(item) {
      return item ? item.properties.label : "";
    },
    defaultInputValue: defaultValue || "",
    onIsOpenChange: (changes) => {
      if (!changes.selectedItem) {
        setValue("postalCode", "");
        setValue("street", "");
        setValue("city", "");
        setValue("housenumber", "" || "",);
        setValue("address", changes.inputValue || "", { shouldDirty: true });
      }
    },
    onSelectedItemChange(changes) {
      setValue("postalCode", changes.selectedItem?.properties.postcode || "");
      setValue("street", changes.selectedItem?.properties.street || "");
      setValue("city", changes.selectedItem?.properties.city || "");
      setValue(
        "housenumber",
        changes.selectedItem?.properties.housenumber || "",
      );
      setValue(
        "address",
        changes.selectedItem?.properties.label || "",
        { shouldDirty: true }
      );
    },
  });

  return (
    <div
      className={cx("input-container address", {
        mobile: isMobile,
        errored: hasError
      })}
    >
      <input type="hidden" {...register("postalCode")} />
      <input type="hidden" {...register("street")} />
      <input type="hidden" {...register("housenumber")} />
      <label htmlFor="address">
        <input
          id="address"
          type="text"
          placeholder=" "
          className="input"
          {...register("address", {
            required,
          })}
          {...getInputProps()}
        />
        <span>{label}</span>
      </label>
      {errorText ? <span className="error">{errorText}</span> : null}
      <ul
        {...getMenuProps()}
        className="search-box"
      >
        {isOpen &&
          adresses.map((item, index) => (
            <li
              key={`${item.properties.id}`}
              {...getItemProps({ item, index })}
            >
              <span>{item.properties.label}</span>
            </li>
          ))}
      </ul>
    </div>
  )
}