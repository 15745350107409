import React from "react";
import Home from "./pages/Home";

import PiwikPro from '@piwikpro/react-piwik-pro';
import useAzureAuth from "utils/hooks/useAzureAuth";
import { useIsAuthenticated } from "@azure/msal-react";
import UpdateInfos from "pages/Update";

PiwikPro.initialize(process.env.REACT_APP_PIWIK_API_KEY || '', process.env.REACT_APP_PIWIK_API_URL || "");

function App() {

  // Hook permettant de vérifier l'authentification
  // Et de réouvrir la popup si besoin
  useAzureAuth();

  const isAuthenticated = useIsAuthenticated();

  if (isAuthenticated) {
    return <UpdateInfos />;
  }

  return (<Home />);
}

export default App;
