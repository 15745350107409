import React, { useContext } from "react";
import cx from "classnames";
import { AppContext } from "contexts/AppContext";
import logo from "./assets/logo.svg";
import separator from "./assets/separator.svg";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "utils/auth";

import "./assets/styles.scss";

function Home() {
  const { isMobile } = useContext(AppContext);
  const { instance } = useMsal();

  function handleLogin() {
    instance.loginPopup(loginRequest)
      .catch((error: any) => console.error(error));
  }

  return (
    <div className={cx("home", { mobile: isMobile })}>
      <div className="box">
        <div className="box-top">
          <div className="box-top-title">
            <h1>PAMI <span className="connect">Connect</span></h1>
            <img className="separator" alt="separator" src={separator} />
          </div>
          <div className="box-top-text">
            <p>Votre accompagnement</p>
            <p>à la modernisation et à l’innovation </p>
            <p>de la flotte fluviale</p>
          </div>
          <img className="logo" alt="logo" src={logo} />
        </div>
        <div className="box-bottom">
          <button onClick={handleLogin}>Se connecter</button>
        </div>
      </div>
    </div>
  );
}

export default Home;
