import { EventType, InteractionType } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useEffect } from "react"
import { loginRequest } from "utils/auth";

export default function useAzureAuth() {
  const { instance: msalInstance } = useMsal();

  useEffect(() => {
    const callbackId = msalInstance.addEventCallback((event: any) => {
      if (event.eventType === EventType.LOGIN_FAILURE) {
        if (
          event.error && event.error.errorMessage.indexOf("AADB2C90118") > -1
        ) {
          if (event.interactionType === InteractionType.Popup) {
            msalInstance.loginPopup(loginRequest)
              .catch((e: any) => {
                return;
              });
          }
        }
      }
    });

    return () => {
      if (callbackId) {
        msalInstance.removeEventCallback(callbackId);
      }
    };
  }, [msalInstance]);
}